<template>
  <div class="benefits">
    <div class="header d-flex flex-row">
      <div class="left d-flex flex-column justify-center">
        <h3>Join the <span>BLOEMENMAKER</span> Family!</h3>
        <p>
          Did you know that we have great advantages for our Flowermaker family?
        </p>

        <div class="scroll">
          <img
            src="@/assets/images/Benefits/arrow-bottom.png"
            alt="scroll"
            class="scroll-arrow"
          />
        </div>
      </div>
      <div class="right d-flex flex-row align-center">
        <img src="@/assets/images/Benefits/main-flower.png" alt="main-flower" />
      </div>
    </div>
    <div class="draft">
      <h3>Becoming part of our family is very easy!</h3>
      <p>You create an account in an instant and tadaaa! You belong!</p>
      <Button :width="250" :backGround="'#000'" :color="'#fff'"
        >Register</Button
      >
    </div>

    <div class="opportunities-wrapper">
      <Opportunities
        :img="'opportunities.png'"
        :category="' '"
        :title="'With Bloemenmaker account you will have:'"
        :btnSlogan="'register'"
      >
        <div class="order-list">
          <ol>
            <li>
              <p>Free delivery</p>
            </li>
            <li><p>Delivery on Sundays is also possible</p></li>
            <li>
              <p>Savings card; save for nice discounts</p>
            </li>
            <li>
              <p>You are the first to know about our new products</p>
            </li>
          </ol>
        </div>
      </Opportunities>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Opportunities from "@/components/Opportunities";

export default {
  name: "Benefits",
  components: {
    Button,
    Opportunities,
  },
};
</script>
